export const STAFF_USER_DATA = "STAFF_USER_DATA";
export const USER_DATA = "USER_DATA";
export const USER_TOKEN = "USER_TOKEN";
export const MERCHANT_DATA = "MERCHANT_DATA";
export const SUPER_ADMIN_DATA = "SUPER_ADMIN_DATA";
export const AGENT_DATA = "AGENT_DATA";
export const STORE_DATA = "STORE_DATA";
export const CASHIER_DATA = "CASHIER_DATA";
export const POS_DATA = "POS_DATA";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const DELETE_RECORD_ROLE = "DELETE_RECORD_ROLE";
export const STORE_ID = "STORE_ID";
export const USER_ROLE = "USER_ROLE";
export const PURCHASE_ORDER_DATA = "PURCHASE_ORDER_DATA";
export const PURCHASE_ORDER_NUMBER = "PURCHASE_ORDER_NUMBER";
export const EMPLOYEE_CLOCKIN_DATE = "EMPLOYEE_CLOCKIN_DATE";
export const EMPLOYEE_CLOCKIN_RANGE = "EMPLOYEE_CLOCKIN_RANGE";
export const EMPLOYEE_NAME = "EMPLOYEE_NAME";
export const CLOCKINOUT_DATE = "CLOCKINOUT_DATE";
export const EMPLOYEE_ID_FOR_CLOCKINREPORT = "EMPLOYEE_ID_FOR_CLOCKINREPORT";
export const PRODUCT_TABLE_COLUMN_DATA = "PRODUCT_TABLE_COLUMN_DATA";
export const CUSTOMER_TABLE_COLUMN_DATA = "CUSTOMER_TABLE_COLUMN_DATA";
export const VIEW_VENDOR_ITEM_NUMBER = "VIEW_VENDOR_ITEM_NUMBER";
export const PURCHASE_ORDER_PLACE_DATA = "PURCHASE_ORDER_PLACE_DATA";
export const CHECK_ORDER_DATA = "CHECK_ORDER_DATA";
// export const INITIALIZE_CUSTOMER_TABLE_COLUMN_DATA =("INITIALIZE_CUSTOMER_TABLE_COLUMN_DATA");
// export const INITIALIZE_PRODUCT_TABLE_COLUMN_DATA="INITIALIZE_PRODUCT_TABLE_COLUMN_DATA"

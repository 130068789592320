import React, { useState } from "react";
import logo from "../assets/forgot.png";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { postReqwithoutHeader } from "../utils/constant";
import debounce from "lodash.debounce";
import { EMAIL_REQUIRED_MSG, VALID_EMAIL_MSG } from "../utils/validationmsg";
import SpinnerComponent from "../components/SpinnerComponent";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [formError, setFormError] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = { ...formError };
    switch (name) {
      case "email":
        error.email =
          value === ""
            ? EMAIL_REQUIRED_MSG
            : !value.match(
                // eslint-disable-next-line
                /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
              )
            ? VALID_EMAIL_MSG
            : "";
        break;
      default:
        break;
    }
    setFormError({ ...error });
  };
  const validate = () => {
    let error = true;
    const submitError = { ...formError };
    if (formData.email === "") {
      error = false;
      submitError.email = EMAIL_REQUIRED_MSG;
    } else if (
      !formData.email.match(
        // eslint-disable-next-line
        /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
      )
    ) {
      error = false;
      submitError.email = VALID_EMAIL_MSG;
    }
    setFormError({ ...submitError });
    return error;
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      try {
        setLoading(true);
        const result = await postReqwithoutHeader("forgetPassword", {
          email: formData.email,
        });
        if (result.data.status === 200) {
          setLoading(false);
          toast.success(result?.data?.message, {
            theme: "colored",
            autoClose: 950,
          });
          navigate("/sign-in");
        } else {
          toast.error(result?.data?.message, {
            theme: "colored",
            autoClose: 950,
          });
        }
      } catch (error) {
        setLoading(false);
        const { response } = error;
        const { data } = response;
        toast.error(data?.message, { theme: "colored", autoClose: 950 });
      }
    }
  };
  const delayCall = debounce(handleSubmit, 1500);
  return (
    <div className="main-wrap login-centerdiv">
      <div className="container">
        <div className="manageContainer">
          <div className="row">
            <div className="col-md-12 box-sett">
              <div className="row">
                <div className="col-lg-6">
                  <div className="img-box p-5 pb-0">
                    <img src={logo} className="w-100" alt="logo" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="p-5 content-box">
                    <h1>Forgot Password?</h1>
                    <p>Enter the email address associated with your account</p>
                    <div className="mt-5">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email address"
                        className="form-control border-bottom border-0"
                        onChange={handleChange}
                      />
                      <p className="errorPara">{formError.email}</p>
                      <div className="row">
                        <div className="col-12 col-md-6 mt-5">
                          <button
                            className="btn secondary-button m-0"
                            onClick={() => {
                              navigate("/sign-in");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-12 col-md-6 mt-md-5 mt-3 text-md-end">
                          {loading ? (
                            <button className="btn main-button m-0" disabled>
                              <SpinnerComponent /> Please wait...
                            </button>
                          ) : (
                            <button
                              className="btn main-button m-0 cstm-save-btn"
                              onClick={delayCall}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;

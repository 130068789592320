import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import NotFound from "../pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import CreatePassword from "../pages/CreatePassword";
import ResetPassword from "../pages/ResetPassword";
import DefaultLayout from "../components/DefaultLayout";
import CustomerForm from "../customerQRForm/CustomerForm";
const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute route="LandingPage" />} />
      <Route path="/sign-in" element={<ProtectedRoute route="SignIn" />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/customer-form" element={<CustomerForm />} />
      <Route
        path="/agent-dashboard"
        element={
          <DefaultLayout children={<ProtectedRoute route="AgentDashboard" />} />
        }
      />
      <Route
        path="/merchant-dashboard"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="MerchantDashboard" />}
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <DefaultLayout children={<ProtectedRoute route="Dashboard" />} />
        }
      />
      <Route
        path="/employeeManagement/add-employee"
        element={
          <DefaultLayout children={<ProtectedRoute route="AddEmployee" />} />
        }
      />
      <Route
        path="/employeeManagement/add-employee-role"
        element={
          <DefaultLayout children={<ProtectedRoute route="AddEmpRole" />} />
        }
      />
      <Route
        path="/employee-permission"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="EmployeePermission" />}
          />
        }
      />
      <Route
        path="/storemanagement/add-pos"
        element={<DefaultLayout children={<ProtectedRoute route="AddPos" />} />}
      />
      <Route
        path="/storemanagement/pos-customize"
        element={
          <DefaultLayout children={<ProtectedRoute route="PosCustomize" />} />
        }
      />
      <Route
        path="/storemanagement/ip-config"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="IpConfiguration" />}
          />
        }
      />
      <Route
        path="/storemanagement/registeredPos-list"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="PosListStoreWise" />}
          />
        }
      />
      <Route
        path="/storemanagement/add-flag"
        element={
          <DefaultLayout children={<ProtectedRoute route="AddFlag" />} />
        }
      />
      <Route
        path="/storemanagement/add-store"
        element={
          <DefaultLayout children={<ProtectedRoute route="AddStore" />} />
        }
      />
      <Route
        path="/storemanagement/add-department"
        element={
          <DefaultLayout children={<ProtectedRoute route="AddDepartment" />} />
        }
      />
      <Route
        path="/storemanagement/add-product"
        element={
          <DefaultLayout children={<ProtectedRoute route="Products" />} />
        }
      />
      <Route
        path="/storemanagement/add-promotion"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="PromotionManagement" />}
          />
        }
      />
      <Route
        path="/storemanagement/tax-mangement"
        element={
          <DefaultLayout children={<ProtectedRoute route="TaxMangement" />} />
        }
      />
      <Route
        path="/storemanagement/card-fee-setup"
        element={
          <DefaultLayout children={<ProtectedRoute route="CardFeeSetup" />} />
        }
      />
      <Route
        path="/storemanagement/mass-product-update"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="MassProductUpdate" />}
          />
        }
      />
      <Route
        path="/storemanagement/purchase-order"
        element={
          <DefaultLayout children={<ProtectedRoute route="PurchaseOrder" />} />
        }
      />
      <Route
        path="/storemanagement/purchase-order/order-history"
        element={
          <DefaultLayout children={<ProtectedRoute route="OrderHistory" />} />
        }
      />
      <Route
        path="/storemanagement/purchase-order/preview-order"
        element={
          <DefaultLayout children={<ProtectedRoute route="PreviewOrder" />} />
        }
      />
      <Route
        path="/storemanagement/store-setting"
        element={
          <DefaultLayout children={<ProtectedRoute route="StoreSetting" />} />
        }
      />
      <Route
        path="/report/product"
        element={
          <DefaultLayout children={<ProtectedRoute route="ProductReport" />} />
        }
      />
      <Route
        path="/report/pay-roll"
        element={
          <DefaultLayout children={<ProtectedRoute route="PayRollReport" />} />
        }
      />
      <Route
        path="/report/productivity"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="ProductivityReport" />}
          />
        }
      />

      <Route
        path="/report/shift-report"
        element={
          <DefaultLayout children={<ProtectedRoute route="ShiftReport" />} />
        }
      />
      <Route
        path="/report/sales-report"
        element={
          <DefaultLayout children={<ProtectedRoute route="SalesReport" />} />
        }
      />
      <Route
        path="/report/department"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="DepartmentReport" />}
          />
        }
      />
      <Route
        path="/report/tax-report"
        element={
          <DefaultLayout children={<ProtectedRoute route="TaxReport" />} />
        }
      />
      <Route
        path="/report/pay-roll"
        element={
          <DefaultLayout children={<ProtectedRoute route="Reports" />} />
        }
      />
      <Route
        path="/report/clock-in-out"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="ClockInOutReport" />}
          />
        }
      />
      <Route
        path="/report/clock-in-out/details"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="ClockInOutDetailsByDay" />}
          />
        }
      />
      <Route
        path="/report/clock-in-out/byDateRange"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="ClockInOutByRange" />}
          />
        }
      />
      <Route
        path="/report/transaction"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="TransactionReport" />}
          />
        }
      />
      <Route
        path="/report/transaction/details/:id/:orderStatus"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="TransactionDetails" />}
          />
        }
      />
      <Route
        path="/report/batch-report"
        element={
          <DefaultLayout children={<ProtectedRoute route="BatchReport" />} />
        }
      />
      <Route
        path="/report/batch-report/details/:id"
        element={
          <DefaultLayout children={<ProtectedRoute route="BatchDetails" />} />
        }
      />
      <Route
        path="/report/cona-report"
        element={
          <DefaultLayout children={<ProtectedRoute route="ConaReport" />} />
        }
      />
      <Route
        path="/report/emp-productivity"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="ProductivityReport" />}
          />
        }
      />
      <Route
        path="/user-management"
        element={
          <DefaultLayout children={<ProtectedRoute route="UserManagement" />} />
        }
      />
      <Route
        path="/customer-engagement"
        element={
          <DefaultLayout
            children={<ProtectedRoute route="CustomerEngagement" />}
          />
        }
      />
      <Route
        path="/manage-agent"
        element={
          <DefaultLayout children={<ProtectedRoute route="ManageAgent" />} />
        }
      />
      <Route
        path="/manage-merchant"
        element={
          <DefaultLayout children={<ProtectedRoute route="ManageMerchant" />} />
        }
      />
      <Route
        path="/store-list"
        element={
          <DefaultLayout children={<ProtectedRoute route="StoreList" />} />
        }
      />
      <Route
        path="/usersetting"
        element={
          <DefaultLayout children={<ProtectedRoute route="UserSetting" />} />
        }
      />
      <Route
        path="/contact-us"
        element={
          <DefaultLayout children={<ProtectedRoute route="ContactUs" />} />
        }
      />
      <Route
        path="/customer-engagement/customer-detail/:id"
        element={
          <DefaultLayout children={<ProtectedRoute route="CustomerDetail" />} />
        }
      />
      <Route
        path="/customer-engagement/manage-group"
        element={
          <DefaultLayout children={<ProtectedRoute route="CustomerGroup" />} />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Routing;

import { getUserToken } from "./utils";
import axios from "axios";
axios.defaults.maxContentLength = 50000000;
//API URL For Local & QA
// const baseUrl = "http://44.205.102.108:8080/api/";
// const baseUrl = "https://splitfirstatm-back-qa.chetu.com/api/";
//API URL For AWS Staging
// const baseUrl = "http://44.198.199.173:8080/api/";
//API URL For AWS Production
const baseUrl = "https://splitpos.com:8080/api/";
export const itemsPerPage = 10;

const createHeader = () => {
  const apitoken = JSON.parse(getUserToken());
  return {
    Authorization: `Bearer ${apitoken}`,
    "Content-Type": "application/json",
  };
};
const handleInvalidToken = (navigate) => {
  localStorage.clear(); // Clear local storage
  navigate("/sign-in"); // Redirect to sign-in
};
const setInterceptors = (navigate) => {
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.message === "Invalid token!") {
        handleInvalidToken(navigate);
      }
      return response;
    },
    (error) => {
      if (error?.response?.data?.message === "Invalid token!") {
        handleInvalidToken(navigate);
      }
      return Promise.reject(error);
    }
  );
};
export const postReq = (apiPath, reqBody, navigate) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.post(path, reqBody, { headers });
};
//Multipart/form-data for image/CSV file upload
const createHeaderForMultipart = () => {
  const apitoken = JSON.parse(getUserToken());
  return {
    Authorization: `Bearer ${apitoken}`,
    "Content-Type": "multipart/form-data",
  };
};
const customerformMultipart = () => {
  return {
    "Content-Type": "multipart/form-data",
  };
};
export const postReqWithFile = (apiPath, reqBody, navigate) => {
  const headers = createHeaderForMultipart();
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.post(path, reqBody, {
    headers,
  });
};

export const postReqForOnlineForm = (apiPath, reqBody, navigate) => {
  const headers = customerformMultipart();
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.post(path, reqBody, { headers });
};
export const postReqwithoutHeader = (apiPath, reqBody, navigate) => {
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.post(path, reqBody);
};

export const getReq = (apiPath, navigate) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.get(path, { headers });
};

//get byid
export const getById = (apiPath, id, navigate) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  setInterceptors(navigate);
  return axios.get(path, {
    headers,
    data: {
      agentId: id,
    },
  });
};

//For update Emp Image
export const putReqForEmp = (apiPath, reqBody) => {
  const headers = createHeaderForMultipart();
  const path = baseUrl + apiPath;
  return axios.put(path, reqBody, { headers });
};
export const putReq = (apiPath, reqBody) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  return axios.put(path, reqBody, { headers });
};
export const delWithBodyReq = (apiPath, reqBody) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  return axios.delete(path, { headers, data: reqBody });
};
export const delReq = (apiPath, id, role) => {
  const headers = createHeader();
  const path = baseUrl + apiPath;
  switch (role) {
    case "department":
      return axios.delete(path, {
        headers,
        data: {
          departmentId: id,
        },
      });
    case "flag":
      return axios.delete(path, {
        headers,
        data: {
          flagId: id,
        },
      });
    case "product":
      return axios.delete(path, {
        headers,
        data: {
          itemId: id,
        },
      });
    case "promotion":
      return axios.delete(path, {
        headers,
        data: {
          promotionId: id,
        },
      });
    case "combinedPromotion":
      return axios.delete(path, {
        headers,
        data: {
          combinedPromotionId: id,
        },
      });
    case "customer":
      return axios.delete(path, {
        headers,
        data: {
          customerId: id,
        },
      });
    case "customerGroup":
      return axios.delete(path, {
        headers,
        data: {
          customerGroupId: id,
        },
      });
    case "selectedItems":
      return axios.delete(path, {
        headers,
        data: {
          selectedItems: id,
        },
      });
    case "empTask":
      return axios.delete(path, {
        headers,
        data: {
          taskId: id,
        },
      });
    default:
      break;
  }
};

import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { getUserToken } from "../utils/utils";
import { Spin } from "antd";
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const CardFeeSetup = React.lazy(() => import("../pages/CardFeeSetup"));
const IpConfiguration = React.lazy(() => import("../pages/IpConfiguration"));
const MerchantDashboard = React.lazy(() =>
  import("../pages/MerchantDashboard")
);
const AddDepartment = React.lazy(() => import("../pages/Departments"));
const Products = React.lazy(() => import("../pages/Products"));
const AddStore = React.lazy(() => import("../pages/StoreManagement"));
const AgentDashboard = React.lazy(() => import("../pages/AgentDashboard"));
const ContactUs = React.lazy(() => import("../pages/ContactUs"));
const StoreSetting = React.lazy(() => import("../pages/StoreSetting"));
const CustomerEngagement = React.lazy(() =>
  import("../pages/CustomerEngagement")
);
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const ManageAgent = React.lazy(() => import("../pages/ManageAgent"));
const ManageMerchant = React.lazy(() => import("../pages/ManageMerchant"));
const PromotionManagement = React.lazy(() =>
  import("../pages/PromotionManagement")
);
const StoreList = React.lazy(() => import("../pages/StoreList"));
const TaxMangement = React.lazy(() => import("../pages/TaxMangement"));
const UserManagement = React.lazy(() => import("../pages/UserManagement"));
const UserSetting = React.lazy(() => import("../pages/UserSetting"));
const AddPos = React.lazy(() => import("../pages/PosManagement"));
const EmployeePermission = React.lazy(() =>
  import("../pages/EmployeePermission")
);
const AddEmpRole = React.lazy(() => import("../pages/EmpRole"));
const AddEmployee = React.lazy(() => import("../pages/Employees"));
const AddFlag = React.lazy(() => import("../pages/Flag"));
const MassProductUpdate = React.lazy(() =>
  import("../pages/MassProductUpdate")
);
const CustomerDetail = React.lazy(() => import("../pages/CustomerDetail"));
const CustomerGroup = React.lazy(() => import("../pages/CustomerGroup"));
const PosListStoreWise = React.lazy(() => import("../pages/PosListStoreWise"));
const ShiftReport = React.lazy(() => import("../reports/ShiftReport"));
const SalesReport = React.lazy(() => import("../reports/SalesReport"));
const ProductReport = React.lazy(() => import("../reports/ProductReport"));
const PayRollReport = React.lazy(() => import("../reports/PayRollReport"));
const ProductivityReport = React.lazy(() =>
  import("../reports/ProductivityReport")
);
const PurchaseOrder = React.lazy(() => import("../pages/PurchaseOrder"));
const TaxReport = React.lazy(() => import("../reports/TaxReport"));
const TransactionReport = React.lazy(() =>
  import("../reports/TransactionReport")
);
const BatchReport = React.lazy(() => import("../reports/BatchReport"));
const ConaReport = React.lazy(() => import("../reports/ConaReport"));

const ClockInOutDetailsByDay = React.lazy(() =>
  import("../reports/ClockInOutDetailsByDay")
);
const OrderHistory = React.lazy(() => import("../pages/OrderHistory"));
const SignIn = React.lazy(() => import("../pages/SignIn"));
const PreviewOrder = React.lazy(() => import("../pages/PreviewOrder"));
const TransactionDetails = React.lazy(() =>
  import("../reports/TransactionDetails")
);
const BatchDetails = React.lazy(() => import("../reports/BatchDetails"));
const DepartmentReport = React.lazy(() =>
  import("../reports/DepartmentReport")
);
const ClockInOutReport = React.lazy(() =>
  import("../reports/ClockInOutReport")
);
const ClockInOutByRange = React.lazy(() =>
  import("../reports/ClockInOutByRange")
);
const PosCustomize = React.lazy(() => import("../pages/PosCustomize"));

const routesForSuperAdmin = {
  Dashboard: (
    <Suspense fallback={<Spin size="large" />}>
      <Dashboard />
    </Suspense>
  ),
  AddStore: (
    <Suspense fallback={<Spin size="large" />}>
      <AddStore />
    </Suspense>
  ),
  ContactUs: (
    <Suspense fallback={<Spin size="large" />}>
      <ContactUs />
    </Suspense>
  ),
  ManageAgent: (
    <Suspense fallback={<Spin size="large" />}>
      <ManageAgent />
    </Suspense>
  ),
  ManageMerchant: (
    <Suspense fallback={<Spin size="large" />}>
      <ManageMerchant />
    </Suspense>
  ),
  StoreList: (
    <Suspense fallback={<Spin size="large" />}>
      <StoreList />
    </Suspense>
  ),
  AddPos: (
    <Suspense fallback={<Spin size="large" />}>
      <AddPos />
    </Suspense>
  ),
  UserManagement: (
    <Suspense fallback={<Spin size="large" />}>
      <UserManagement />
    </Suspense>
  ),
  UserSetting: (
    <Suspense fallback={<Spin size="large" />}>
      <UserSetting />
    </Suspense>
  ),
  ShiftReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ShiftReport />
    </Suspense>
  ),
  SalesReport: (
    <Suspense fallback={<Spin size="large" />}>
      <SalesReport />
    </Suspense>
  ),
  DepartmentReport: (
    <Suspense fallback={<Spin size="large" />}>
      <DepartmentReport />
    </Suspense>
  ),
  ProductReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductReport />
    </Suspense>
  ),
  TaxReport: (
    <Suspense fallback={<Spin size="large" />}>
      <TaxReport />
    </Suspense>
  ),
  PayRollReport: (
    <Suspense fallback={<Spin size="large" />}>
      <PayRollReport />
    </Suspense>
  ),
  ProductivityReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductivityReport />
    </Suspense>
  ),
};

const routesForMerchant = {
  MerchantDashboard: (
    <Suspense fallback={<Spin size="large" />}>
      <MerchantDashboard />
    </Suspense>
  ),
  AddEmployee: (
    <Suspense fallback={<Spin size="large" />}>
      <AddEmployee />
    </Suspense>
  ),
  AddEmpRole: (
    <Suspense fallback={<Spin size="large" />}>
      <AddEmpRole />
    </Suspense>
  ),
  AddDepartment: (
    <Suspense fallback={<Spin size="large" />}>
      <AddDepartment />
    </Suspense>
  ),
  AddFlag: (
    <Suspense fallback={<Spin size="large" />}>
      <AddFlag />
    </Suspense>
  ),
  Products: (
    <Suspense fallback={<Spin size="large" />}>
      <Products />
    </Suspense>
  ),
  AddStore: (
    <Suspense fallback={<Spin size="large" />}>
      <AddStore />
    </Suspense>
  ),
  PosListStoreWise: (
    <Suspense fallback={<Spin size="large" />}>
      <PosListStoreWise />
    </Suspense>
  ),
  StoreList: (
    <Suspense fallback={<Spin size="large" />}>
      <StoreList />
    </Suspense>
  ),
  EmployeePermission: (
    <Suspense fallback={<Spin size="large" />}>
      <EmployeePermission />
    </Suspense>
  ),
  CustomerEngagement: (
    <Suspense fallback={<Spin size="large" />}>
      <CustomerEngagement />
    </Suspense>
  ),

  PosCustomize: (
    <Suspense fallback={<Spin size="large" />}>
      <PosCustomize />
    </Suspense>
  ),
  IpConfiguration: (
    <Suspense fallback={<Spin size="large" />}>
      <IpConfiguration />
    </Suspense>
  ),
  PromotionManagement: (
    <Suspense fallback={<Spin size="large" />}>
      <PromotionManagement />
    </Suspense>
  ),
  CardFeeSetup: (
    <Suspense fallback={<Spin size="large" />}>
      <CardFeeSetup />
    </Suspense>
  ),
  TaxMangement: (
    <Suspense fallback={<Spin size="large" />}>
      <TaxMangement />
    </Suspense>
  ),
  UserManagement: (
    <Suspense fallback={<Spin size="large" />}>
      <UserManagement />
    </Suspense>
  ),
  UserSetting: (
    <Suspense fallback={<Spin size="large" />}>
      <UserSetting />
    </Suspense>
  ),
  MassProductUpdate: (
    <Suspense fallback={<Spin size="large" />}>
      <MassProductUpdate />
    </Suspense>
  ),
  CustomerDetail: (
    <Suspense fallback={<Spin size="large" />}>
      <CustomerDetail />
    </Suspense>
  ),
  CustomerGroup: (
    <Suspense fallback={<Spin size="large" />}>
      <CustomerGroup />
    </Suspense>
  ),
  PurchaseOrder: (
    <Suspense fallback={<Spin size="large" />}>
      <PurchaseOrder />
    </Suspense>
  ),
  SalesReport: (
    <Suspense fallback={<Spin size="large" />}>
      <SalesReport />
    </Suspense>
  ),
  ShiftReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ShiftReport />
    </Suspense>
  ),
  DepartmentReport: (
    <Suspense fallback={<Spin size="large" />}>
      <DepartmentReport />
    </Suspense>
  ),
  ProductReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductReport />
    </Suspense>
  ),
  TaxReport: (
    <Suspense fallback={<Spin size="large" />}>
      <TaxReport />
    </Suspense>
  ),
  ClockInOutByRange: (
    <Suspense fallback={<Spin size="large" />}>
      <ClockInOutByRange />
    </Suspense>
  ),
  PayRollReport: (
    <Suspense fallback={<Spin size="large" />}>
      <PayRollReport />
    </Suspense>
  ),
  ProductivityReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductivityReport />
    </Suspense>
  ),
  ClockInOutReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ClockInOutReport />
    </Suspense>
  ),
  ClockInOutDetailsByDay: (
    <Suspense fallback={<Spin size="large" />}>
      <ClockInOutDetailsByDay />
    </Suspense>
  ),
  TransactionDetails: (
    <Suspense fallback={<Spin size="large" />}>
      <TransactionDetails />
    </Suspense>
  ),
  TransactionReport: (
    <Suspense fallback={<Spin size="large" />}>
      <TransactionReport />
    </Suspense>
  ),
  BatchReport: (
    <Suspense fallback={<Spin size="large" />}>
      <BatchReport />
    </Suspense>
  ),
  BatchDetails: (
    <Suspense fallback={<Spin size="large" />}>
      <BatchDetails />
    </Suspense>
  ),
  ConaReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ConaReport />
    </Suspense>
  ),
  OrderHistory: (
    <Suspense fallback={<Spin size="large" />}>
      <OrderHistory />
    </Suspense>
  ),
  PreviewOrder: (
    <Suspense fallback={<Spin size="large" />}>
      <PreviewOrder />
    </Suspense>
  ),
  StoreSetting: (
    <Suspense fallback={<Spin size="large" />}>
      <StoreSetting />
    </Suspense>
  ),
  ContactUs: (
    <Suspense fallback={<Spin size="large" />}>
      <ContactUs />
    </Suspense>
  ),
};

const routesForAgent = {
  AgentDashboard: (
    <Suspense fallback={<Spin size="large" />}>
      <AgentDashboard />
    </Suspense>
  ),
  ManageMerchant: (
    <Suspense fallback={<Spin size="large" />}>
      <ManageMerchant />
    </Suspense>
  ),
  AddStore: (
    <Suspense fallback={<Spin size="large" />}>
      <AddStore />
    </Suspense>
  ),

  PromotionManagement: (
    <Suspense fallback={<Spin size="large" />}>
      <PromotionManagement />
    </Suspense>
  ),
  StoreList: (
    <Suspense fallback={<Spin size="large" />}>
      <StoreList />
    </Suspense>
  ),
  UserManagement: (
    <Suspense fallback={<Spin size="large" />}>
      <UserManagement />
    </Suspense>
  ),
  UserSetting: (
    <Suspense fallback={<Spin size="large" />}>
      <UserSetting />
    </Suspense>
  ),
  AddPos: (
    <Suspense fallback={<Spin size="large" />}>
      <AddPos />
    </Suspense>
  ),
  ShiftReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ShiftReport />
    </Suspense>
  ),
  SalesReport: (
    <Suspense fallback={<Spin size="large" />}>
      <SalesReport />
    </Suspense>
  ),
  DepartmentReport: (
    <Suspense fallback={<Spin size="large" />}>
      <DepartmentReport />
    </Suspense>
  ),
  ProductReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductReport />
    </Suspense>
  ),
  TaxReport: (
    <Suspense fallback={<Spin size="large" />}>
      <TaxReport />
    </Suspense>
  ),
  PayRollReport: (
    <Suspense fallback={<Spin size="large" />}>
      <PayRollReport />
    </Suspense>
  ),
  ProductivityReport: (
    <Suspense fallback={<Spin size="large" />}>
      <ProductivityReport />
    </Suspense>
  ),
  ContactUs: (
    <Suspense fallback={<Spin size="large" />}>
      <ContactUs />
    </Suspense>
  ),
};

const routesForSign = {
  SignIn: (
    <Suspense fallback={<Spin size="large" />}>
      <SignIn />
    </Suspense>
  ),
  LandingPage: (
    <Suspense fallback={<Spin size="large" />}>
      <LandingPage />
    </Suspense>
  ),
};

const ProtectedRoute = (props) => {
  const role = useSelector((store) => store?.userReducer?.userRole);
  const matchRoute = () => {
    const userToken = getUserToken();
    const token = userToken ? JSON.parse(userToken) : null;
    if (token && token !== null) {
      if (role === "super-admin" || role === "admin") {
        const component = routesForSuperAdmin[props.route] || (
          <Navigate to="/dashboard" />
        );
        return component;
      } else if (role === "merchant") {
        const component = routesForMerchant[props.route] || (
          <Navigate to="/merchant-dashboard" />
        );
        return component;
      } else if (role === "super-merchant") {
        const component = routesForMerchant[props.route] || (
          <Navigate to="/merchant-dashboard" />
        );
        return component;
      } else if (role === "agent") {
        const component = routesForAgent[props.route] || (
          <Navigate to="/agent-dashboard" />
        );
        return component;
      } else {
        return <Navigate to="/sign-in" />;
      }
    } else {
      const component = routesForSign[props.route] || (
        <Navigate to="/sign-in" />
      );
      return component;
    }
  };
  return matchRoute();
};
export default ProtectedRoute;

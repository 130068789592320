import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./CustomerForm.css";
import logo from "./logo.png";
import { postReqForOnlineForm } from "../utils/constant";
import { toast } from "react-toastify";
import {
  ONLY_TEN_NUMBER_ALLOW,
  PHONE_REQUIRED_MSG,
  VALID_EMAIL_MSG,
} from "../utils/validationmsg";
const CustomerForm = () => {
  const signatureRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    addressLineOne: "",
    addressLineTwo: "",
    businessPhoneNumber: "",
    customerName: "",
    mobileNumber: "",
    email: "",
    state: "",
    city: "",
    zipCode: "",
    isCokeCustomer: "",
    b2bTaxID: "",
    cokeCN: "",
    isSignupForRedRewards: "",
    currentPurchasedCokeFrom: "",
    salesTaxCertificate: "",
    driverLicenseFile: "",
    voidedCheckFile: "",
    signedCustomerFile: null,
    printName: "",
    termsAndConditionsFirst: false,
    isAllowSMSMailPromotion: false,
  });
  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    addressLineOne: "",
    addressLineTwo: "",
    businessPhoneNumber: "",
    customerName: "",
    mobileNumber: "",
    email: "",
    state: "",
    city: "",
    zipCode: "",
    isCokeCustomer: "",
    b2bTaxID: "",
    cokeCN: "",
    isSignupForRedRewards: "",
    currentPurchasedCokeFrom: "",
    isAllowSMSMailPromotion: "",
    salesTaxCertificate: "",
    driverLicenseFile: "",
    voidedCheckFile: "",
    printName: "",
    termsAndConditionsFirst: "",
  });

  const handleChange = (event) => {
    const { name, type, checked, files } = event.target;
    let value;
    if (type === "checkbox") {
      value = checked;
    } else if (type === "file") {
      const file = files[0];
      // Validate file type (allow only images, PDFs, and TIFFs)
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
        "image/tiff", // Add this line to allow TIFF files
      ];
      if (file && !allowedTypes.includes(file.type)) {
        setFormError((prevError) => ({
          ...prevError,
          [name]: "Please select an image (JPEG, PNG, GIF, TIFF) or PDF only.",
        }));
        return;
      }
      setFormError((prevError) => ({
        ...prevError,
        [name]: "",
      }));
      value = file;
    } else {
      value = event.target.value;
    }
    let errorMessage = "";
    switch (name) {
      case "customerName":
        if (!value.trim()) {
          errorMessage = "Business name is required.";
        }
        break;
      case "addressLineOne":
        if (!value.trim()) {
          errorMessage = "Business address is required.";
        }
        break;
      case "firstName":
        if (!value.trim()) {
          errorMessage = "First name is required.";
        }
        break;
      case "email":
        errorMessage =
          value === ""
            ? ""
            : !value.match(
                // eslint-disable-next-line
                /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
              )
            ? VALID_EMAIL_MSG
            : "";
        break;
      case "isCokeCustomer":
        if (!value) {
          errorMessage = "Please select any value.";
        }
        break;
      case "printName":
        if (!value.trim()) {
          errorMessage = "Print name is required.";
        }
        break;
      default:
        break;
    }
    // Update the formData and formError states
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormError((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  const validateValue = (e) => {
    const data = e.target.value;
    const pattern = /^[0-9\b]+$/;
    if (data.trim() === "" || pattern.test(data.trim())) {
      handleChange(e);
    } else {
      e.preventDefault();
      e.target.value = e.target.value.slice(0, -1);
    }
  };

  const convertMobileNumber = (e) => {
    const { name, value } = e.target;
    let myVal = value.replace(/\D/g, "");
    const error = { ...formError };
    switch (name) {
      case "mobileNumber":
        error.mobileNumber =
          myVal === ""
            ? PHONE_REQUIRED_MSG
            : myVal.length > 10
            ? ONLY_TEN_NUMBER_ALLOW
            : myVal.length === 10
            ? ""
            : "Enter a valid 10-digit mobile number";
        break;
      case "businessPhoneNumber":
        error.businessPhoneNumber =
          myVal === ""
            ? "Business Phone Number is Required!"
            : myVal.length > 10
            ? ONLY_TEN_NUMBER_ALLOW
            : myVal.length === 10
            ? ""
            : "Enter a valid 10-digit phone number";
        break;
      default:
        break;
    }
    setFormError(error);
    if (myVal.length === 10) {
      const formattedNumber = `${myVal.slice(0, 3)}-${myVal.slice(
        3,
        6
      )}-${myVal.slice(6, 10)}`;
      if (name === "mobileNumber") {
        setPhoneNumber(formattedNumber);
      } else if (name === "businessPhoneNumber") {
        setBusinessPhone(formattedNumber);
      }
    } else {
      if (name === "mobileNumber") {
        setPhoneNumber(myVal);
      } else if (name === "businessPhoneNumber") {
        setBusinessPhone(myVal);
      }
    }
    setFormData({
      ...formData,
      [name]: myVal,
    });
  };

  //Open Camera
  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      // Handle stream (e.g., display video in a video element)
      // Here you can set the stream to a video element to display the camera feed
      // Clean up: stop the stream when done
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      toast.error("No camera found or access denied.", {
        theme: "colored",
        autoClose: 800,
      });
    }
  };

  const validate = () => {
    let isValid = true;
    const newFormError = { ...formError };
    if (!formData.firstName) {
      newFormError.firstName = "First name is required";
      isValid = false;
    } else {
      newFormError.firstName = "";
    }
    if (!formData.customerName) {
      newFormError.customerName = "Business name is required";
      isValid = false;
    } else {
      newFormError.customerName = "";
    }
    if (!formData.addressLineOne) {
      newFormError.addressLineOne = "Business address is required";
      isValid = false;
    } else {
      newFormError.addressLineOne = "";
    }
    if (!formData.businessPhoneNumber) {
      newFormError.businessPhoneNumber = "Business phone number is required";
      isValid = false;
    } else {
      newFormError.businessPhoneNumber = "";
    }
    if (!formData.email) {
      newFormError.email = "Applicant email is required";
      isValid = false;
    } else {
      newFormError.email = "";
    }
    if (!formData.mobileNumber) {
      newFormError.mobileNumber = "Applicant mobile number is required";
      isValid = false;
    } else {
      newFormError.mobileNumber = "";
    }
    if (!formData.isCokeCustomer) {
      newFormError.isCokeCustomer = "Please select any option";
      isValid = false;
    } else {
      newFormError.isCokeCustomer = "";
    }
    if (!formData.cokeCN) {
      newFormError.cokeCN = "Coke customer number is required";
      isValid = false;
    } else {
      newFormError.cokeCN = "";
    }
    if (!formData.isSignupForRedRewards) {
      newFormError.isSignupForRedRewards = "Please select any option";
      isValid = false;
    } else {
      newFormError.isSignupForRedRewards = "";
    }
    if (!formData.printName) {
      newFormError.printName = "Print name is required";
      isValid = false;
    } else {
      newFormError.printName = "";
    }
    setFormError(newFormError);

    return isValid;
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
    setFormData({ ...formData, signedCustomerFile: null });
  };
  // const handleSignature = (dataURL) => {
  //   setFormData({ ...formData, signedCustomerFile: dataURL });
  // };

  const handleEnd = () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const blob = dataURLToBlob(dataURL);
      const file = new File([blob], "signature.png", {
        type: blob.type,
        lastModified: Date.now(),
      });
      // onSignature(file);
      setFormData({ ...formData, signedCustomerFile: file });
    }
  };
  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.termsAndConditionsFirst) {
      toast.error("Please accept the Terms and Conditions", {
        theme: "colored",
        autoClose: 800,
      });
      return;
    }
    if (validate()) {
      const reqBody = {
        ...formData,
        address: formData.addressLineOne + " " + formData.addressLineTwo,
      };
      if (formData.isCokeCustomer === "yes") {
        reqBody.isCokeCustomer = true;
        reqBody.cokeCN = formData.cokeCN;
      } else {
        reqBody.isCokeCustomer = false;
      }
      if (formData.isSignupForRedRewards === "yes") {
        reqBody.isSignupForRedRewards = true;
      } else if (formData.isSignupForRedRewards === "no") {
        reqBody.isSignupForRedRewards = false;
      }
      if (!formData.signedCustomerFile) {
        alert("Please provide a signature.");
        return;
      } else {
        reqBody.signedCustomerFile = formData.signedCustomerFile;
      }
      setLoading(true);
      try {
        const response = await postReqForOnlineForm(
          `web/addCustomerDetails`,
          reqBody
        );
        const { status, message } = response?.data;
        if (status === 200) {
          setLoading(false);
          toast.success(message, { theme: "colored", autoClose: 800 });
          clearSignature();
          setPhoneNumber("");
          setBusinessPhone("");
          setFormData({
            firstName: "",
            lastName: "",
            addressLineOne: "",
            addressLineTwo: "",
            businessPhoneNumber: "",
            customerName: "",
            mobileNumber: "",
            email: "",
            state: "",
            city: "",
            zipCode: "",
            isCokeCustomer: "",
            b2bTaxID: "",
            cokeCN: "",
            isSignupForRedRewards: "",
            currentPurchasedCokeFrom: "",
            salesTaxCertificate: "",
            driverLicenseFile: "",
            voidedCheckFile: "",
            signedCustomerFile: null,
            printName: "",
            isAllowSMSMailPromotion: false,
            termsAndConditionsFirst: false,
          });
          setFormError({
            firstName: "",
            lastName: "",
            addressLineOne: "",
            addressLineTwo: "",
            businessPhoneNumber: "",
            customerName: "",
            mobileNumber: "",
            email: "",
            state: "",
            city: "",
            zipCode: "",
            isCokeCustomer: "",
            b2bTaxID: "",
            cokeCN: "",
            isSignupForRedRewards: "",
            currentPurchasedCokeFrom: "",
            isAllowSMSMailPromotion: false,
            salesTaxCertificate: "",
            driverLicenseFile: "",
            voidedCheckFile: "",
            printName: "",
            termsAndConditionsFirst: "",
          });
        }
      } catch (error) {
        setLoading(false);
        const { message } = error?.response?.data;
        toast.error(message, { theme: "colored", autoClose: 800 });
      }
    } else {
      toast.error("Please fill the required fields.", {
        theme: "colored",
        autoClose: 800,
      });
    }
  };
  return (
    <div>
      <section className="signle-pageForm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="mainLogo-box">
                <img src={logo} alt="logo" />
              </div>
              <div className="formCard">
                <div className="cardHeader">
                  <h4 className="cardMain-head">New Customer Form</h4>
                </div>
                <div className="cardForm-input">
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="customerName"
                          className="form-label custmform-label"
                        >
                          BUSINESS NAME
                          <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="customerName"
                          name="customerName"
                          value={formData?.customerName}
                          onChange={handleChange}
                          aria-describedby="customerName"
                        />
                        {formError.customerName && (
                          <span className="text-danger">
                            {formError.customerName}
                          </span>
                        )}
                        <div
                          id="customerName"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Name of Business
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="customerName"
                          className="form-label custmform-label"
                        >
                          Business Address
                          <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="addressLineOne"
                          name="addressLineOne"
                          value={formData?.addressLineOne}
                          onChange={handleChange}
                          maxLength={65}
                          aria-describedby="addressLineOne"
                        />
                        {formError.addressLineOne && (
                          <span className="text-danger">
                            {formError.addressLineOne}
                          </span>
                        )}
                        <div
                          id="addressLineOne"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Street Address
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="addressLineTwo"
                          name="addressLineTwo"
                          maxLength={65}
                          value={formData?.addressLineTwo}
                          aria-describedby="addressLineTwo"
                          onChange={handleChange}
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div
                          id="addressLineTwo"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Address Line 2
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="inputField-grp">
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="city"
                          name="city"
                          aria-describedby="city"
                          maxLength={65}
                          value={formData?.city}
                          onChange={handleChange}
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div id="city" className="form-text fieldInfo-txt mt-0">
                          City
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="inputField-grp">
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="state"
                          name="state"
                          aria-describedby="state"
                          value={formData?.state}
                          onChange={handleChange}
                          maxLength={65}
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div
                          id="state"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          State/Region/Province
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="inputField-grp">
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="zipCode"
                          name="zipCode"
                          aria-describedby="zipCode"
                          value={formData?.zipCode}
                          onChange={validateValue}
                          maxLength={5}
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div
                          id="zipCode"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Postal / Zip Code
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 mb-3">
                      <div className="inputField-grp">
                        <input
                          type="text"
                          className="form-control custminputField"
                          id=""
                          aria-describedby=""
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div id="" className="form-text fieldInfo-txt mt-0">
                          Country
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="businessPhoneNumber"
                          className="form-label custmform-label"
                        >
                          Business Phone Number
                          <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="businessPhoneNumber"
                          name="businessPhoneNumber"
                          aria-describedby="businessPhoneNumber"
                          onChange={(e) => convertMobileNumber(e)}
                          value={businessPhone}
                          maxLength={12}
                        />
                        {formError.businessPhoneNumber && (
                          <span className="text-danger">
                            {formError.businessPhoneNumber}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label className="form-label custmform-label">
                          Applicant Name
                          <sup className="text-danger">*</sup>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="inputField-grp">
                            <input
                              type="text"
                              className="form-control custminputField"
                              id="firstName"
                              name="firstName"
                              aria-describedby="firstName"
                              maxLength={60}
                              value={formData?.firstName}
                              onChange={handleChange}
                            />
                            {formError.firstName && (
                              <span className="text-danger">
                                {formError.firstName}
                              </span>
                            )}
                            <div
                              id="firstName"
                              className="form-text fieldInfo-txt mt-0"
                            >
                              First Name
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="inputField-grp">
                            <input
                              type="text"
                              className="form-control custminputField"
                              id="lastName"
                              name="lastName"
                              aria-describedby="lastName"
                              onChange={handleChange}
                              value={formData?.lastName}
                              maxLength={60}
                            />
                            <span className="text-danger d-none">
                              Enter a value for this field.
                            </span>
                            <div
                              id="lastName"
                              className="form-text fieldInfo-txt mt-0"
                            >
                              Last Name
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="inputField-grp">
                            <label
                              htmlFor=""
                              className="form-label custmform-label"
                            >
                              Applicant Mobile Number
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control custminputField"
                              id="mobileNumber"
                              name="mobileNumber"
                              value={phoneNumber}
                              onChange={(e) => convertMobileNumber(e)}
                              maxLength={12}
                              autoComplete="new-mobileNumber"
                            />
                            {formError.mobileNumber && (
                              <span className="text-danger">
                                {formError.mobileNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="inputField-grp">
                            <label
                              htmlFor="email"
                              className="form-label custmform-label"
                            >
                              Applicant Email Address
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control custminputField"
                              id="email"
                              name="email"
                              value={formData?.email}
                              aria-describedby="email"
                              onChange={handleChange}
                            />
                            {formError.email && (
                              <span className="text-danger">
                                {formError.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="isCokeCustomer"
                          className="form-label custmform-label"
                        >
                          Does your Business buy directly from Coke Florida or
                          have an existing agreement with them?
                          <sup className="text-danger">*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="isCokeCustomer"
                          value={formData?.isCokeCustomer}
                          onChange={handleChange}
                          aria-label="Multiple select example"
                        >
                          <option value="">Select option</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        {formError.isCokeCustomer && (
                          <span className="text-danger">
                            {formError.isCokeCustomer}
                          </span>
                        )}
                        <div
                          id="isCokeCustomer"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          (Does Coke deliver to your store or do you have a
                          contract with them?)
                        </div>
                      </div>
                    </div>
                    {formData.isCokeCustomer === "yes" && (
                      <>
                        <div className="col-lg-12 mb-3">
                          <div className="inputField-grp">
                            <div className="row">
                              <div className="col-lg-6">
                                <label
                                  htmlFor="cokeCN"
                                  className="form-label custmform-label"
                                >
                                  Coke Customer Number
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="inputField-grp">
                                  <input
                                    type="text"
                                    className="form-control custminputField"
                                    id="cokeCN"
                                    name="cokeCN"
                                    value={formData?.cokeCN}
                                    maxLength={10}
                                    onChange={handleChange}
                                    aria-describedby="cokeCN"
                                  />
                                  {formError.cokeCN && (
                                    <span className="text-danger">
                                      {formError.cokeCN}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="isSignupForRedRewards"
                                  className="form-label custmform-label"
                                >
                                  Would you like to sign up for Red Rewards?
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="inputField-grp">
                                  <select
                                    className="form-select"
                                    name="isSignupForRedRewards"
                                    value={formData?.isSignupForRedRewards}
                                    onChange={handleChange}
                                    aria-label="Multiple select example"
                                  >
                                    <option value="">Select option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                  {formError.isSignupForRedRewards && (
                                    <span className="text-danger">
                                      {formError.isSignupForRedRewards}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="currentPurchasedCokeFrom"
                          className="form-label custmform-label"
                        >
                          Where are you currently purchasing Coca Cola Product
                          from today?
                        </label>
                        <input
                          type="text"
                          className="form-control custminputField"
                          id="currentPurchasedCokeFrom"
                          name="currentPurchasedCokeFrom"
                          value={formData?.currentPurchasedCokeFrom}
                          onChange={handleChange}
                          aria-describedby="currentPurchasedCokeFrom"
                        />
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div
                          id="currentPurchasedCokeFrom"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Example: Club Store, Restaurant Depot, Supermarkets,
                          Direct from Manufacturer, Other Distributor.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="b2bTaxID"
                          className="form-label custmform-label"
                        >
                          SALES TAX ID
                        </label>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="inputField-grp">
                              <input
                                type="text"
                                className="form-control custminputField"
                                id="b2bTaxID"
                                name="b2bTaxID"
                                value={formData?.b2bTaxID}
                                maxLength={20}
                                onChange={handleChange}
                                aria-describedby="b2bTaxID"
                              />
                              <span className="text-danger d-none">
                                Enter a value for this field.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="salesTaxCertificate"
                          className="form-label custmform-label"
                        >
                          SALES TAX CERTIFICATE
                        </label>
                        <div className="imgUpload-box">
                          <div className="imgUpload-optn">
                            <span className="textImguplod">Choose-file</span>
                            <div className="impgUploadbtn-grp">
                              <label
                                htmlFor="salesTaxCertificate"
                                className="btnUpload"
                              >
                                <input
                                  type="file"
                                  id="salesTaxCertificate"
                                  name="salesTaxCertificate"
                                  onChange={handleChange}
                                />
                                <span className="iconimg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                </span>
                              </label>
                              <span className="btnUpload" onClick={openCamera}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-camera"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        {formData.salesTaxCertificate && (
                          <div className="file-details mt-2 p-2 border rounded">
                            <div className="d-flex align-items-center">
                              <span className="iconimg mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-file-earmark"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5z" />
                                  <path d="M10 4.5V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5h-3a1 1 0 0 1-1-1z" />
                                </svg>
                              </span>
                              <div>
                                <strong>
                                  {formData.salesTaxCertificate.name}
                                </strong>
                                <div>
                                  {formData.salesTaxCertificate.size} KB
                                </div>
                              </div>
                              <button
                                className="btn btn-link ml-auto"
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    salesTaxCertificate: "",
                                  })
                                }
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )}
                        {formError.salesTaxCertificate && (
                          <span className="text-danger">
                            {formError.salesTaxCertificate}
                          </span>
                        )}
                        <div
                          id="salesTaxCertificate"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Upload Photo of Sales Tax Certificate
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="driverLicenseFile"
                          className="form-label custmform-label"
                        >
                          UPLOAD COPY OF DRIVER LICENSE
                        </label>
                        <div className="imgUpload-box">
                          <div className="imgUpload-optn">
                            <span className="textImguplod">Choose-file</span>
                            <div className="impgUploadbtn-grp">
                              <label
                                htmlFor="driverLicenseFile"
                                className="btnUpload"
                              >
                                <input
                                  type="file"
                                  name="driverLicenseFile"
                                  id="driverLicenseFile"
                                  onChange={handleChange}
                                />
                                <span className="iconimg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                </span>
                              </label>
                              <span className="btnUpload" onClick={openCamera}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-camera"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        {formData.driverLicenseFile && (
                          <div className="file-details mt-2 p-2 border rounded">
                            <div className="d-flex align-items-center">
                              <span className="iconimg mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-file-earmark"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5z" />
                                  <path d="M10 4.5V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5h-3a1 1 0 0 1-1-1z" />
                                </svg>
                              </span>
                              <div>
                                <strong>
                                  {formData.driverLicenseFile.name}
                                </strong>
                                <div>{formData.driverLicenseFile.size} KB</div>
                              </div>
                              <button
                                className="btn btn-link ml-auto"
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    driverLicenseFile: "",
                                  })
                                }
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )}
                        {formError.driverLicenseFile && (
                          <span className="text-danger">
                            {formError.driverLicenseFile}
                          </span>
                        )}
                        <div
                          id="driverLicenseFile"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Please upload a copy of Driver's License
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="voidedCheckFile"
                          className="form-label custmform-label"
                        >
                          IF PAYING BY CHEQUE - UPLOAD COPY OF VOIDED CHEQUE
                        </label>
                        <div className="imgUpload-box">
                          <div className="imgUpload-optn">
                            <span className="textImguplod">Choose-file</span>
                            <div className="impgUploadbtn-grp">
                              <label
                                htmlFor="voidedCheckFile"
                                className=" btnUpload"
                              >
                                <input
                                  type="file"
                                  name="voidedCheckFile"
                                  id="voidedCheckFile"
                                  onChange={handleChange}
                                />
                                <span className="iconimg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                </span>
                              </label>
                              <span className="btnUpload" onClick={openCamera}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-camera"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        {formData.voidedCheckFile && (
                          <div className="file-details mt-2 p-2 border rounded">
                            <div className="d-flex align-items-center">
                              <span className="iconimg mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-file-earmark"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5z" />
                                  <path d="M10 4.5V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5h-3a1 1 0 0 1-1-1z" />
                                </svg>
                              </span>
                              <div>
                                <strong>{formData.voidedCheckFile.name}</strong>
                                <div>{formData.voidedCheckFile.size} KB</div>
                              </div>
                              <button
                                className="btn btn-link ml-auto"
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    voidedCheckFile: "",
                                  })
                                }
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )}
                        {formError.voidedCheckFile && (
                          <span className="text-danger">
                            {formError.voidedCheckFile}
                          </span>
                        )}{" "}
                        <div
                          id="voidedCheckFile"
                          className="form-text fieldInfo-txt mt-0"
                        >
                          Please upload a copy of voided cheque
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="termsAndConditions"
                          className="form-label custmform-label"
                        >
                          Terms and Conditions
                          <sup className="text-danger">*</sup>
                        </label>
                        <div className="termsContainer">
                          <div className="terms-head">Terms and Conditions</div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              1. Acceptance of Terms
                            </span>
                            <p>
                              By submitting a customer application to XPRESS
                              Cash &amp; Carry, you agree to abide by these
                              Terms and Conditions. These terms may be updated
                              periodically, and it is your responsibility to
                              review them for any changes.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              2. Eligibility
                            </span>
                            <p>
                              To apply for an account with XPRESS Cash &amp;
                              Carry, you must be at least 18 years old and
                              authorized to conduct business on behalf of the
                              entity or organization you represent. XPRESS Cash
                              &amp; Carry reserves the right to accept or reject
                              any application at its discretion.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              3. Information Accuracy
                            </span>
                            <p>
                              You agree to provide accurate and complete
                              information in your application. Should any
                              information provided in your application change,
                              you are responsible for notifying XPRESS Cash
                              &amp; Carry of these changes immediately.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              4. Account Approval
                            </span>
                            <p>
                              Submission of an application does not guarantee
                              approval. XPRESS Cash &amp; Carry will review your
                              application and may request additional information
                              before granting account access.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              5. Account Usage
                            </span>
                            <p>
                              Approved customers will receive an account with
                              XPRESS Cash &amp; Carry. This account is strictly
                              for business purposes and is non-transferable. You
                              are responsible for maintaining the
                              confidentiality of your account information and
                              are liable for all activities that occur under
                              your account.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              6. Payment Terms
                            </span>
                            <p>
                              All purchases made through XPRESS Cash &amp; Carry
                              are subject to our payment terms. Payments must be
                              made according to the agreed-upon terms and within
                              the specified payment period. Late payments may
                              incur interest and could result in account
                              suspension or termination.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              7. Pricing and Availability
                            </span>
                            <p>
                              XPRESS Cash &amp; Carry reserves the right to
                              adjust pricing and product availability at any
                              time without prior notice. All prices are
                              exclusive of taxes, which will be applied as per
                              applicable laws.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              8. Returns and Refunds
                            </span>
                            <p>
                              Our return and refund policies are outlined
                              separately and are incorporated into these Terms
                              and Conditions. By accepting these terms, you
                              acknowledge that you have read and understood our
                              return and refund policies.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              9. Limitation of Liability
                            </span>
                            <p>
                              XPRESS Cash &amp; Carry is not liable for any
                              direct, indirect, incidental, special, or
                              consequential damages arising from your use of the
                              account or products purchased through the account,
                              even if advised of the possibility of such
                              damages.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              10. Termination
                            </span>
                            <p>
                              XPRESS Cash &amp; Carry reserves the right to
                              terminate your account at any time, with or
                              without cause. Upon termination, all outstanding
                              balances must be paid immediately.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              11. Governing Law
                            </span>
                            <p>
                              These Terms and Conditions are governed by and
                              construed in accordance with the laws of
                              Florida/United States, without regard to its
                              conflict of law provisions.
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              12. Dispute Resolution
                            </span>
                            <p>
                              Any disputes arising from these Terms and
                              Conditions or your use of the XPRESS Cash &amp;
                              Carry account will be resolved through binding
                              arbitration in Tampa,Florida/United States
                            </p>
                          </div>
                          <div className="termsitem">
                            <span className="termLine-head">
                              13. Contact Information
                            </span>
                            <p>
                              For any questions or concerns regarding these
                              Terms and Conditions, please contact XPRESS Cash
                              &amp; Carry at customers@xpressdistributors.com
                            </p>
                          </div>
                        </div>
                        <span className="text-danger d-none">
                          Enter a value for this field.
                        </span>
                        <div
                          id="termsAndConditionsFirst"
                          className="form-text mt-0 inputField-grp"
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              name="termsAndConditionsFirst"
                              id="termsAndConditionsFirst"
                              // value={formData.termsAndConditionsFirst}
                              checked={formData.termsAndConditionsFirst}
                              onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="">
                              I accept the Terms and Conditions.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="inputField-grp">
                        <label
                          htmlFor="isAllowSMSMailPromotion"
                          className="form-label custmform-label"
                        >
                          XPRESS Cash &amp; Carry Text Updates Terms and
                          Conditions
                        </label>
                        <div className="termsContainer">
                          <p className="termsitem">
                            By providing your mobile number, you consent to
                            receive periodic text messages from XPRESS Cash
                            &amp; Carry regarding your account, orders,
                            promotions, and other relevant information, with the
                            understanding that message and data rates may apply,
                            and you can opt-out at any time by replying "STOP."
                          </p>
                        </div>
                      </div>
                      <span className="text-danger d-none">
                        Enter a value for this field.
                      </span>
                      <div
                        id="isAllowSMSMailPromotion"
                        className="form-text mt-0 inputField-grp"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            name="isAllowSMSMailPromotion"
                            id="isAllowSMSMailPromotion"
                            onChange={handleChange}
                            checked={formData.isAllowSMSMailPromotion}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isAllowSMSMailPromotion"
                          >
                            I accept the Terms and Conditions.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="row ">
                        <h3 className="sign_heading">Sign &amp; Print Name</h3>
                        <div className="col-lg-6 mb-3">
                          <div className="inputField-grp">
                            <label
                              htmlFor="printName"
                              className="form-label custmform-label"
                            >
                              Print Name
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control custminputField"
                              id="printName"
                              name="printName"
                              onChange={handleChange}
                              value={formData?.printName}
                              maxLength={65}
                            />
                            {formError.printName && (
                              <span className="text-danger">
                                {formError.printName}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="inputField-grp signature">
                            <label
                              htmlFor=""
                              className="form-label custmform-label"
                            >
                              Signature
                              <sup className="text-danger">*</sup>
                            </label>
                            <div>
                              <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{
                                  width: 350,
                                  height: 200,
                                }}
                                onEnd={handleEnd}
                              />
                            </div>
                            <span
                              className="text-danger cursor-pointer"
                              onClick={clearSignature}
                            >
                              <u>Clear</u>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div className="col-lg-12 text-center submit_btn1">
                        <button disabled>Submitting...</button>
                      </div>
                    ) : (
                      <div className="col-lg-12 text-center submit_btn1">
                        <button onClick={handleSubmit}>Submit</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CustomerForm;
